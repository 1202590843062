<template>
  <app-collapse>
    <app-collapse-item title="1">
      <template #header>
        <div class="lead collapse-title">
          Где взять ClientID и Client secret
        </div>
      </template>
      <p>
        Перейдите на страницу <a
          href="https://oauth.yandex.ru/"
          target="_blank"
        >oauth.yandex.ru</a>
      </p>
      <p>
        Создайте приложение.
      </p>
      <p>
        На вопрос <strong>Для какой платформы нужно приложение? *</strong><br>
        Выберите <strong>Веб-сервисы</strong><br>
      </p>
      <p>
        В появившееся поле <strong>Callback URL</strong><br>
        скопируйте данный адрес <code>{{ baseUrl }}/callbacks/yandex-market-fbs/token</code>
      </p>
      <p>
        На вопрос <strong>Какие данные вам нужны? *</strong><br>
        В разделе <strong>Яндекс.Маркет / Поиск по товарам</strong> отметьте пункт <strong>API Яндекс.Маркета / Поиска по товарам для партнеров</strong>
      </p>
      <p>
        Заполните остальные необходимые вам данные и нажмите кнопку <strong>Создать приложение</strong>
      </p>
      <p>
        Перед вами откроется страница вашего приложения, на которой вы можете найти свои <strong>ClientID</strong> и <strong>Client secret</strong>
      </p>
    </app-collapse-item>
    <app-collapse-item title="2">
      <template #header>
        <div class="lead collapse-title">
          Где взять Идентификатор бизнес-аккаунта
        </div>
      </template>
      <p>
        Перейдите на страницу <a
          href="https://partner.market.yandex.ru/businesses"
          target="_blank"
        >partner.market.yandex.ru/businesses</a>
      </p>
      <p>
        Под именем вашего бизнес-аккаунта(создайте если его нет) находится <strong>ID бизнес-аккаунта</strong>
      </p>
    </app-collapse-item>
    <app-collapse-item title="2">
      <template #header>
        <div class="lead collapse-title">
          Где взять Идентификатор и Авторизационный токен кампании
        </div>
      </template>
      <p>
        Перейдите на страницу <a
          href="https://partner.market.yandex.ru/businesses"
          target="_blank"
        >partner.market.yandex.ru/businesses</a>
      </p>
      <p>
        Откройте ваш бизнес аккаунт (Или создайте если его нет)
      </p>
      <p>
        В разделе <strong>Подключение</strong> создайте (или выберите существующий) магазин типа <strong>FBS</strong>
      </p>

      <p>
        Перейдите в раздел <strong>Настройки / Настройки Api</strong>
      </p>
      <p>
        Перед вами откроется страница вашего приложения, на которой вы можете найти свой <strong>Номер</strong> и <strong>Авторизационный токен</strong> компании.
      </p>
      <p>
        Здесь же необходимо настроить <strong>URL для запросов API</strong><br>
        в данное поле скопируйте адрес <code>{{ baseUrl }}/callbacks/yandex-market-fbs</code>
      </p>
      <p>
        <strong>Способ авторизации</strong> выберите <strong>HEADER</strong>
      </p>
    </app-collapse-item>
    <app-collapse-item title="3">
      <template #header>
        <div class="lead collapse-title">
          Где взять Идентификатор склада кампании
        </div>
      </template>
      <p>
        После того как вы получили Идентификатор и Авторизационный токен кампании. Перейдите в раздел
        <strong>Логистика / Склады</strong> вашего бизнес аккаунт.
      </p>
      <p>
        Перед вами появится список ваших магазинов
      </p>
      <p>
        Возьмите <strong>ID склада</strong> из строки с вашим магазином.
      </p>
    </app-collapse-item>
  </app-collapse>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  name: 'FAQInfo',
  components: {
    AppCollapse, AppCollapseItem,
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_API_URL.slice(0, -4),
    }
  },
}
</script>

<style scoped lang="scss">

</style>
