<template>
  <div v-if="getItemError">
    <b-alert
      variant="danger"
      show
    >
      <h4 class="alert-heading">
        Ошибка при получении данных интеграции
      </h4>
      <div class="alert-body">
        Не найдена интеграция с этим идентификатором. Можете перейти в
        <b-link
          class="alert-link"
          :to="{ name: 'integrations'}"
        >
          Список интеграций
        </b-link>
        и выбрать другую.
      </div>
    </b-alert>
  </div>
  <b-overlay
    v-else
    :show="loading"
    spinner-variant="primary"
  >
    <b-card>
      <div class="">
        <b-row v-if="error_message">
          <b-col>
            <small
              class="text-danger d-block mb-1"
            >
              {{ error_message }}
            </small>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
          >
            <b-form-group
              label="Название *"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model.trim="item.name"
                placeholder="Введите название"
                :state="get(validation, 'name[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'name[0]')"
                class="text-danger"
              >
                {{ get(validation, 'name[0]') }}
              </small>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
          >
            <b-form-group
              label="Тип *"
              label-for="i-type"
            >
              <b-form-input
                id="key"
                :value="get(types.find(i => i.value === item.type), 'label', '')"
                readonly=""
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
          >
            <b-form-group
              label="ClientID *"
              label-for="client_id"
            >
              <b-form-input
                id="client_id"
                :value="item.client_id"
                placeholder="Введите ClientID"
                readonly=""
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
          >
            <b-form-group
              label="Client secret *"
              label-for="client_secret"
            >
              <b-form-input
                id="client_secret"
                v-model.trim="item.client_secret"
                placeholder="Введите Client secret"
                :state="get(validation, 'client_secret[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'client_secret[0]')"
                class="text-danger"
              >
                {{ get(validation, 'client_secret[0]') }}
              </small>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <b-form-group
              label="Идентификатор бизнес-аккаунта *"
              label-for="business_id"
            >
              <b-form-input
                id="campaign"
                :value="item.business_id"
                readonly
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <b-form-group
              label="Идентификатор кампании *"
              label-for="campaign"
            >
              <b-form-input
                id="campaign"
                :value="item.campaign"
                placeholder="Введите Идентификатор кампании"
                readonly
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <b-form-group
              label="Авторизационный токен кампании *"
              label-for="campaign_token"
            >
              <b-form-input
                id="campaign_token"
                v-model.trim="item.campaign_token"
                placeholder="Введите Авторизационный токен кампании"
                :state="get(validation, 'campaign_token[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'campaign_token[0]')"
                class="text-danger"
              >
                {{ get(validation, 'campaign_token[0]') }}
              </small>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <b-form-group
              label="Идентификатор склада *"
              label-for="warehouse_id"
            >
              <b-form-input
                id="warehouse_id"
                v-model.trim="item.warehouse_id"
                placeholder="Введите идентификатор склада"
                :state="get(validation, 'warehouse_id[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'warehouse_id[0]')"
                class="text-danger"
              >
                {{ get(validation, 'warehouse_id[0]') }}
              </small>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <b-form-group
              label="Включить НДС"
              label-for="include_vat"
            >
              <b-form-checkbox
                id="include_vat"
                v-model="item.include_vat"
                :state="get(validation, 'include_vat[0]') ? false : null"
                switch
                :value="true"
                :unchecked-value="false"
              >
                <template v-if="item.include_vat">
                  Вкл
                </template>
                <template v-else>
                  Выкл
                </template>
              </b-form-checkbox>
              <small
                v-if="!!get(validation, 'include_vat[0]')"
                class="text-danger"
              >
                {{ get(validation, 'include_vat[0]') }}
              </small>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <b-form-group
              label="Использовать пользовательский идентификатор"
              label-for="include_vat"
              description="Использование в интеграции пользовательского идентификатора торгового предложения вместо системного. Изменить значение после создания интеграции будет невозможно."
            >
              <b-form-checkbox
                id="use_external_public_id"
                :checked="item.use_external_public_id"
                switch
                :value="true"
                :unchecked-value="false"
                disabled
              >
                <template v-if="item.use_external_public_id">
                  Вкл
                </template>
                <template v-else>
                  Выкл
                </template>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col
            cols="12"
          >
            <h4 class="mb-1">
              Правила выгрузки
            </h4>
          </b-col>
          <b-col
            cols="12"
          >
            <remain-prices-rules
              :price-rules.sync="item.remain_by_price_rules"
            />
            <remain-margin-rules
              :margin-rules.sync="item.remain_by_margin_rules"
            />
            <dimensions-rules
              :dimensions-rules.sync="item.dimensions_rules"
            />

          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col>
            <b-button
              variant="primary"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="updateItem"
            >
              Сохранить
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <warehouses-in-integration :integration="item" />
    <b-card>
      <FAQInfo />
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BAlert, BButton, BCard, BCol, BFormGroup, BFormInput, BLink, BOverlay, BRow, BFormCheckbox,
} from 'bootstrap-vue'
import get from 'lodash/get'
import { getCurrentInstance, ref, onMounted } from 'vue'
import { updateYandexMarketFbsIntegration as updateYandexMarketFbsIntegrationApi, getYandexMarketFbsIntegration as getYandexMarketFbsIntegrationApi } from '@/services/main-api/integrations/yandex-market-fbs'
import useCrudEdit from '@/composables/useCrudEdit'
import { types } from '@/composables/useIntegrations'
import RemainPricesRules from '@/modules/integrations/components/RemainPricesRules.vue'
import RemainMarginRules from '@/modules/integrations/components/RemainMarginRules.vue'
import DimensionsRules from '@/modules/integrations/components/DimensionsRules.vue'
import WarehousesInIntegration from '../WarehousesInIntegration.vue'
import FAQInfo from '../FAQInfo.vue'

export default {
  name: 'EditForm',
  components: {
    RemainPricesRules,
    RemainMarginRules,
    DimensionsRules,
    WarehousesInIntegration,
    BAlert,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BOverlay,
    BFormCheckbox,
    FAQInfo,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
  },
  setup(props) {
    const instance = getCurrentInstance().proxy
    const getItemError = ref(false)

    const getItem = async () => {
      try {
        const res = await getYandexMarketFbsIntegrationApi({ id: props.id })
        instance.item = {
          ...res.data.data,
          type: 'yandex_market_fbs',
        }
      } catch (e) {
        instance.item = {}
        instance.getItemError = true
      }
    }

    const updateEntity = async () => {
      await updateYandexMarketFbsIntegrationApi({
        id: instance.item.id,
        name: instance.item.name,
        client_secret: instance.item.client_secret,
        campaign_token: instance.item.campaign_token,
        warehouse_id: instance.item.warehouse_id,
        include_vat: instance.item.include_vat,
        remain_by_price_rules: instance.item.remain_by_price_rules,
        remain_by_margin_rules: instance.item.remain_by_margin_rules,
        dimensions_rules: instance.item.dimensions_rules,
      })
    }

    const {
      item,
      loading,
      validation,
      error_message,
      clearForm,
      updateItem,
    } = useCrudEdit({
      item: {
        id: null,
        name: '',
        client_secret: '',
        campaign_token: '',
        warehouse_id: '',
        include_vat: false,
        remain_by_price_rules: [],
        remain_by_margin_rules: [],
        dimensions_rules: [],
      },
      validation: {
        rules: {
          name: 'required',
          client_secret: 'required',
          campaign_token: 'required',
          warehouse_id: 'required',
        },
        customErrorMessages: {
          'required.name': 'Укажите название',
          'required.client_secret': 'Укажите Client secret',
          'required.campaign_token': 'Укажите авторизационный токен кампании',
          'required.warehouse_id,': 'Укажите идентификатор склада кампании',
        },
      },
      getItem,
      updateEntity,
      getTextAfterUpdate: () => 'Интеграция была изменена.',
      runAfterUpdate: async () => {
        await instance.$router.go(-1)
      },
    })

    onMounted(() => {
      if (!instance.$store.getters['workingMode/mode']) {
        this.$router.push({ name: 'dashboard' })
      }
    })

    return {
      item,
      loading,
      validation,
      error_message,
      clearForm,
      updateItem,

      types,

      get,
      getItemError,
    }
  },
}
</script>
