<template>
  <b-card
    title="Правила по габаритам и весам (брутто):"
    class="border mb-1"
  >
    <b-form-row>
      <b-col
        cols="12"
      >
        <b-form-group
          label="Категория"
          label-for="product_category_id"
        >
          <v-select
            id="select_prices_rules_type"
            v-model.trim="itemValue.product_category_id"
            placeholder="Выберите категорию"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="categories"
            :reduce="val => val.id"
            :clearable="false"
            label="label"
            input-id="prices_rules_type"
            :class="{
              'is-invalid': !!get(validation, 'product_category_id[0]')
            }"
          />

          <small
            v-if="!!get(validation, 'product_category_id[0]')"
            class="text-danger"
          >
            {{ get(validation, 'product_category_id[0]') }}
          </small>

        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label="Длина"
          label-for="length"
        >
          <b-input-group
            append="мм"
          >
            <b-form-input
              id="length"
              v-model.trim="itemValue.length"
              placeholder="Длина"
              :state="get(validation, 'length[0]') ? false : null"
              type="number"
              lang="ru"
            />
          </b-input-group>

          <small
            v-if="!!get(validation, 'length[0]')"
            class="text-danger"
          >
            {{ get(validation, 'length[0]') }}
          </small>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label="Ширина"
          label-for="width"
        >
          <b-input-group
            append="мм"
          >
            <b-form-input
              id="width"
              v-model.trim="itemValue.width"
              placeholder="Ширина"
              :state="get(validation, 'width[0]') ? false : null"
              type="number"
              lang="ru"
            />
          </b-input-group>

          <small
            v-if="!!get(validation, 'width[0]')"
            class="text-danger"
          >
            {{ get(validation, 'width[0]') }}
          </small>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label="Высота"
          label-for="height"
        >
          <b-input-group
            append="мм"
          >
            <b-form-input
              id="height"
              v-model.trim="itemValue.height"
              placeholder="Высота"
              :state="get(validation, 'height[0]') ? false : null"
              type="number"
              lang="ru"
            />
          </b-input-group>

          <small
            v-if="!!get(validation, 'height[0]')"
            class="text-danger"
          >
            {{ get(validation, 'height[0]') }}
          </small>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label="Вес"
          label-for="weight"
        >
          <b-input-group
            append="г"
          >
            <b-form-input
              id="weight"
              v-model.trim="itemValue.weight"
              placeholder="Вес"
              :state="get(validation, 'weight[0]') ? false : null"
              type="number"
              lang="ru"
            />
          </b-input-group>

          <small
            v-if="!!get(validation, 'weight[0]')"
            class="text-danger"
          >
            {{ get(validation, 'weight[0]') }}
          </small>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-button
      variant="outline-primary"
      size="sm"
      @click="addRule"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-50"
      />
      <span class="align-middle">Добавить</span>
    </b-button>

    <b-table-simple
      :striped="true"
      :bordered="false"
      :borderless="true"
      :outlined="false"
      :small="false"
      :hover="false"
      :dark="false"
      :fixed="false"
      :responsive="true"
      class="mt-2 mb-0"
    >
      <b-thead>
        <b-tr>
          <b-th>Категория</b-th>
          <b-th>Длина</b-th>
          <b-th>Ширина</b-th>
          <b-th>Высота</b-th>
          <b-th>Вес</b-th>
          <b-th>Действия</b-th>
        </b-tr>
      </b-thead>
      <b-tbody
        v-if="dimensionsRules.length"
      >
        <b-tr
          v-for="(dimensionsRule, dimensionsRulesIndex) in dimensionsRules"
          :key="'dr-' + dimensionsRulesIndex"
        >
          <b-td>
            {{ get(categories.find(c => c.id === dimensionsRule.product_category_id), 'name') }}<br>
            <small>id: {{ dimensionsRule.product_category_id }}</small>
          </b-td>
          <b-td>
            {{ dimensionsRule.length }} мм
          </b-td>
          <b-td>
            {{ dimensionsRule.width }} мм
          </b-td>
          <b-td>
            {{ dimensionsRule.height }} мм
          </b-td>
          <b-td>
            {{ dimensionsRule.weight }} г
          </b-td>
          <b-td>
            <b-button
              variant="outline-danger"
              class="btn-icon"
              size="sm"
              @click="deleteRule(dimensionsRulesIndex)"
            >
              <feather-icon icon="XIcon" />
            </b-button>
          </b-td>
        </b-tr>
      </b-tbody>
      <b-tbody
        v-else
      >
        <b-tr>
          <b-td colspan="6">
            <div class="text-secondary">
              -
            </div>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </b-card>
</template>

<script>
import get from 'lodash/get'
import vSelect from 'vue-select'

import {
  BCard, BCol, BFormGroup, BFormInput, BButton,
  BInputGroup, BFormRow,
  BTableSimple, BTbody, BTr, BTh, BTd, BThead,
} from 'bootstrap-vue'

import {
  ref, onMounted, getCurrentInstance,
} from 'vue'
import { getCategories as getCategoriesApi } from '@/services/main-api/catalog/products/categories'

export default {
  name: 'DimensionsRules',
  components: {
    vSelect,
    BCard,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroup,
    BFormRow,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BThead,
  },
  props: {
    dimensionsRules: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const instance = getCurrentInstance().proxy
    const validation = ref({})

    const categories = ref([])

    const loading = ref(false)

    const getCategories = async () => {
      loading.value = true
      try {
        const res = await getCategoriesApi({
          project: instance.$store.getters['workingMode/selected_project_id'],
          perPage: 9999,
          sortedBy: 'asc',
          orderBy: 'name',
          includes: 'children',
        })

        categories.value = res.data.data.filter(i => !Array.isArray(i.children) || !i.children.length)
          .map(i => ({
            ...i,
            label: `[${i.id}] ${i.name}`,
          }))
      } catch (e) {
        categories.value = []
      }
      loading.value = false
    }

    // Rules Fields
    const itemValue = ref({
      product_category_id: null,
      length: null, // Длина в мм
      width: null, // Ширина в мм
      height: null, // Высота в мм
      weight: null, // Вес в граммах
    })

    const clearForm = () => {
      itemValue.value.product_category_id = null
      itemValue.value.length = null
      itemValue.value.width = null
      itemValue.value.height = null
      itemValue.value.weight = null
    }

    const clearValidation = () => {
      validation.value = {}
    }

    const checkValidation = () => {
      clearValidation()

      let hasError = false

      if (!itemValue.value.product_category_id) {
        hasError = true
        validation.value.product_category_id = ['Выберите категорию']
      }

      if (!itemValue.value.length) {
        hasError = true
        validation.value.length = ['Введите длину']
      }

      if (!itemValue.value.width) {
        hasError = true
        validation.value.width = ['Введите ширину']
      }

      if (!itemValue.value.height) {
        hasError = true
        validation.value.height = ['Введите высоту']
      }

      if (!itemValue.value.weight) {
        hasError = true
        validation.value.weight = ['Введите вес']
      }

      return !hasError
    }

    const deleteRule = index => {
      const rules = [...props.dimensionsRules]
      rules.splice(index, 1)
      emit('update:dimensionsRules', rules)
    }

    const isProgressAdd = ref(false)
    const addRule = () => {
      if (isProgressAdd.value || !checkValidation()) {
        return
      }

      isProgressAdd.value = true

      const rule = {
        product_category_id: itemValue.value.product_category_id,
        length: itemValue.value.length,
        width: itemValue.value.width,
        height: itemValue.value.height,
        weight: itemValue.value.weight,
      }

      console.log(rule)

      emit('update:dimensionsRules', [...props.dimensionsRules, rule])

      clearForm()
      isProgressAdd.value = false
    }

    onMounted(() => {
      getCategories()
    })

    return {
      categories,

      itemValue,
      validation,

      addRule,
      deleteRule,

      get,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
