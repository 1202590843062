<template>
  <b-card title="Склады">
    <p>Здесь можно настроить список складов участвующих в интеграции</p>
    <p>Сортировка определяет приоритет склада при поступлении заказа от Яндекс Маркета. Чем выше значение, тем выше приоритет.</p>

    <b-overlay
      :show="loading"
      spinner-variant="primary"
    >

      <div v-if="items.length">
        <b-table-simple
          :striped="false"
          :bordered="false"
          :borderless="true"
          :outlined="false"
          :small="false"
          :hover="false"
          :dark="false"
          :fixed="false"
          :responsive="true"
          class="mt-2"
        >
          <b-thead>
            <b-th colspan="3">
              Участвуют
            </b-th>
          </b-thead>
          <template v-if="activeItems.length">
            <draggable
              tag="tbody"
              role="rowgroup"
              handle=".wi-item__move"
              :list="activeItems"
              draggable=".wi-item"
              @end="sortItem"
            >
              <b-tr
                v-for="activeItem in activeItems"
                :key="'a' + activeItem.id"
                class="wi-item"
              >
                <b-td>
                  <b-button
                    variant="fade"
                    class="btn-icon"
                    :class="{
                      'wi-item__move': $store.getters['workingMode/checkPermission']('manage_integrations')
                    }"
                    size="sm"
                    :disabled="!$store.getters['workingMode/checkPermission']('manage_integrations')"
                  >
                    <feather-icon icon="MoveIcon" />
                  </b-button>
                </b-td>
                <b-th>
                  {{ activeItem.name }}
                </b-th>
                <b-td class="text-right">
                  <b-button
                    variant="outline-danger"
                    class="btn-icon"
                    size="sm"
                    :disabled="!$store.getters['workingMode/checkPermission']('manage_integrations')"
                    @click="detachItem(activeItem)"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </b-td>
              </b-tr>
            </draggable>
          </template>
          <b-tbody v-else>
            <b-tr>
              <b-td colspan="3">
                <div class="text-secondary">
                  -
                </div>
              </b-td>
            </b-tr>
          </b-tbody>

          <b-thead>
            <b-th colspan="3">
              Не участвуют
            </b-th>
          </b-thead>
          <b-tbody>
            <template v-if="inactiveItems.length">
              <b-tr
                v-for="inactiveItem in inactiveItems"
                :key="'ia' + inactiveItem.id"
              >
                <b-td />
                <b-th>
                  {{ inactiveItem.name }}
                </b-th>
                <b-td class="text-right">
                  <b-button
                    variant="outline-primary"
                    class="btn-icon"
                    size="sm"
                    :disabled="!$store.getters['workingMode/checkPermission']('manage_integrations')"
                    @click="attachItem(inactiveItem)"
                  >
                    <feather-icon icon="PlusIcon" />
                  </b-button>
                </b-td>
              </b-tr>
            </template>
            <b-tr
              v-else
            >
              <b-td colspan="3">
                <div class="text-secondary">
                  -
                </div>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>
      <div v-else>
        <b-alert
          variant="secondary"
          show
        >
          <h4 class="alert-heading">
            В данный момент у вас нет складов
          </h4>
          <div class="alert-body">
            Можете перейти в <b-link :to="{ name: 'warehouses' }">
              раздел управления складами
            </b-link>
          </div>
        </b-alert>
      </div>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard,
  BOverlay,
  BAlert,
  BButton,
  BLink,
  BTableSimple,
  BTbody,
  BThead,
  BTr,
  BTd,
  BTh,
} from 'bootstrap-vue'
import {
  ref, onMounted, computed,
} from 'vue'
import get from 'lodash/get'
import draggable from 'vuedraggable'
import useWarehouseList from '@/composables/useWarehouseList'
import { attachYandexMarketDbs, detachYandexMarketDbs, updateAttachingYandexMarketDbs } from '@/services/main-api/shop/warehouses'

export default {
  name: 'WarehousesInIntegration',
  components: {
    BCard,
    BOverlay,
    BAlert,
    BButton,
    BLink,
    BTableSimple,
    BTbody,
    BTr,
    BTd,
    BTh,
    BThead,
    draggable,
  },
  props: {
    integration: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { getItems, items } = useWarehouseList()

    const loading = ref(false)

    const inactiveItems = computed(() => items.value.filter(i => !get(i, 'yandex_market_dbs', []).find(p => get(p, 'pivot.yandex_market_dbs_id') === props.integration.id && get(p, 'pivot.warehouse_id') === i.id)))
    const activeItems = computed(() => items.value
      .map(i => ({
        ...i,
        now_integration: get(i, 'yandex_market_dbs', []).find(p => get(p, 'pivot.yandex_market_dbs_id') === props.integration.id && get(p, 'pivot.warehouse_id') === i.id),
      }))
      .filter(i => !!i.now_integration)
      .sort((a, b) => (a.now_integration.pivot.sort_order > b.now_integration.pivot.sort_order ? 1 : -1)))

    const getData = async () => {
      await getItems({
        includes: 'yandex_market_dbs',
      })
    }

    const attachItem = async item => {
      loading.value = true
      try {
        await attachYandexMarketDbs({
          id: item.id,
          ymdbsID: props.integration.id,
        })
        await getData()
      } catch (e) {
        // ...
      } finally {
        loading.value = false
      }
    }

    const detachItem = async item => {
      loading.value = true
      try {
        await detachYandexMarketDbs({
          id: item.id,
          ymdbsID: props.integration.id,
        })
        await getData()
      } catch (e) {
        // ...
      } finally {
        loading.value = false
      }
    }

    const updateItem = async params => {
      try {
        await updateAttachingYandexMarketDbs(params)
      } catch (e) {
        // ..
      }
    }

    const sortItem = async ({ oldIndex, newIndex }) => {
      loading.value = true

      const sortItems = activeItems.value.map((oi, index) => ({
        id: oi.id,
        ymdbsID: props.integration.id,
        sort_order: index,
        name: oi.name,
      }))

      // sortItems[oldIndex].sort_order = newIndex
      // sortItems[newIndex].sort_order = oldIndex

      const promises = sortItems.map(updateItem)
      await Promise.all(promises)

      await getData()

      loading.value = false
    }

    onMounted(() => {
      loading.value = true
      getData()
      loading.value = false
    })

    return {
      items,
      activeItems,
      inactiveItems,
      loading,

      attachItem,
      detachItem,
      sortItem,
    }
  },
}
</script>

<style scoped lang="scss">
.wi-item__move{
  cursor: move !important;
}
</style>
