<template>
  <component
    :is="formComponent"
    :id="id"
  />
</template>

<script>
import camelCase from 'lodash/camelCase'
import YandexMarketFbsForm from '../components/yandex-market-fbs/forms/EditForm.vue'
import YandexMarketDbsForm from '../components/yandex-market-dbs/forms/EditForm.vue'
import MerlionFreshYmfbsForm from '../components/merlion-fresh-ymfbs/forms/EditForm.vue'
import MerlionYmdbsForm from '../components/merlion-ymdbs/forms/EditForm.vue'

export default {
  name: 'EditItemView',
  components: {
    YandexMarketFbsForm,
    YandexMarketDbsForm,
    MerlionFreshYmfbsForm,
    MerlionYmdbsForm,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    type: {
      type: [String, Number],
      required: true,
    },
  },
  setup(props) {
    const formComponent = camelCase(`${props.type}_form`)
    return {
      formComponent,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
