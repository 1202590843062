<template>
  <div v-if="getItemError">
    <b-alert
      variant="danger"
      show
    >
      <h4 class="alert-heading">
        Ошибка при получении данных интеграции
      </h4>
      <div class="alert-body">
        Не найдена интеграция с этим идентификатором. Можете перейти в
        <b-link
          class="alert-link"
          :to="{ name: 'integrations'}"
        >
          Список интеграций
        </b-link>
        и выбрать другую.
      </div>
    </b-alert>
  </div>
  <b-overlay
    v-else
    :show="loading"
    spinner-variant="primary"
  >
    <b-card>
      <div class="">
        <b-row v-if="error_message">
          <b-col>
            <small
              class="text-danger d-block mb-1"
            >
              {{ error_message }}
            </small>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
          >
            <b-form-group
              label="Название *"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model.trim="item.name"
                placeholder="Введите название"
                :state="get(validation, 'name[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'name[0]')"
                class="text-danger"
              >
                {{ get(validation, 'name[0]') }}
              </small>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <h4>
              Yandex Market DBS
            </h4>
          </b-col>

          <b-col
            cols="12"
          >
            <b-form-group
              label="Тип *"
              label-for="i-type"
            >
              <b-form-input
                id="key"
                :value="get(types.find(i => i.value === item.type), 'label', '')"
                readonly=""
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
          >
            <b-form-group
              label="ClientID *"
              label-for="client_id"
            >
              <b-form-input
                id="client_id"
                :value="item.client_id"
                placeholder="Введите ClientID"
                readonly=""
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
          >
            <b-form-group
              label="Client secret *"
              label-for="client_secret"
            >
              <b-form-input
                id="client_secret"
                v-model.trim="item.client_secret"
                placeholder="Введите Client secret"
                :state="get(validation, 'client_secret[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'client_secret[0]')"
                class="text-danger"
              >
                {{ get(validation, 'client_secret[0]') }}
              </small>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <b-form-group
              label="Идентификатор бизнес-аккаунта *"
              label-for="business_id"
            >
              <b-form-input
                id="business_id"
                :value="item.business_id"
                readonly
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <b-form-group
              label="Идентификатор кампании *"
              label-for="campaign"
            >
              <b-form-input
                id="campaign"
                :value="item.campaign"
                placeholder="Введите Идентификатор кампании"
                readonly
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <b-form-group
              label="Авторизационный токен кампании *"
              label-for="campaign_token"
            >
              <b-form-input
                id="campaign_token"
                v-model.trim="item.campaign_token"
                placeholder="Введите Авторизационный токен кампании"
                :state="get(validation, 'campaign_token[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'campaign_token[0]')"
                class="text-danger"
              >
                {{ get(validation, 'campaign_token[0]') }}
              </small>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <b-form-group
              label="Идентификатор склада *"
              label-for="warehouse_id"
            >
              <b-form-input
                id="warehouse_id"
                v-model.trim="item.warehouse_id"
                placeholder="Введите идентификатор склада"
                :state="get(validation, 'warehouse_id[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'warehouse_id[0]')"
                class="text-danger"
              >
                {{ get(validation, 'warehouse_id[0]') }}
              </small>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <b-form-group
              label="Включить НДС"
              label-for="include_vat"
            >
              <b-form-checkbox
                id="include_vat"
                v-model="item.include_vat"
                :state="get(validation, 'include_vat[0]') ? false : null"
                switch
                :value="true"
                :unchecked-value="false"
              >
                <template v-if="item.include_vat">
                  Вкл
                </template>
                <template v-else>
                  Выкл
                </template>
              </b-form-checkbox>
              <small
                v-if="!!get(validation, 'include_vat[0]')"
                class="text-danger"
              >
                {{ get(validation, 'include_vat[0]') }}
              </small>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <b-form-group
              label="Использовать пользовательский идентификатор"
              label-for="include_vat"
              description="Использование в интеграции пользовательского идентификатора торгового предложения вместо системного. Изменить значение после создания интеграции будет невозможно."
            >
              <b-form-checkbox
                id="use_external_public_id"
                :checked="item.use_external_public_id"
                switch
                :value="true"
                :unchecked-value="false"
                disabled
              >
                <template v-if="item.use_external_public_id">
                  Вкл
                </template>
                <template v-else>
                  Выкл
                </template>
              </b-form-checkbox>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <b-form-group
              label="Старый магазин"
              label-for="old_shop"
              description="Этот параметр влияет на то, как будет передаваться информация по ценам."
            >
              <b-form-checkbox
                id="old_shop"
                v-model="item.old_shop"
                :state="get(validation, 'old_shop[0]') ? false : null"
                switch
                :value="true"
                :unchecked-value="false"
              >
                <template v-if="item.old_shop">
                  Да
                </template>
                <template v-else>
                  Нет
                </template>
              </b-form-checkbox>
              <small
                v-if="!!get(validation, 'old_shop[0]')"
                class="text-danger"
              >
                {{ get(validation, 'old_shop[0]') }}
              </small>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <b-form-group
              label="Тип доставки *"
              label-for="delivery_type"
            >
              <b-form-input
                id="delivery_type"
                v-model.trim="item.delivery_type"
                placeholder="Выберите тип доставки"
                readonly
                :state="get(validation, 'delivery_type[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'delivery_type[0]')"
                class="text-danger"
              >
                {{ get(validation, 'delivery_type[0]') }}
              </small>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <b-form-group
              label="Название доставки *"
              label-for="delivery_name"
            >
              <b-form-input
                id="delivery_name"
                v-model.trim="item.delivery_name"
                placeholder="Выберите название доставки"
                :state="get(validation, 'delivery_name[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'delivery_name[0]')"
                class="text-danger"
              >
                {{ get(validation, 'delivery_name[0]') }}
              </small>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <b-form-group
              label="ИНН продавца *"
              label-for="seller_inn"
            >
              <b-form-input
                id="seller_inn"
                v-model.trim="item.seller_inn"
                placeholder="Выберите ИНН продавца"
                :state="get(validation, 'seller_inn[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'seller_inn[0]')"
                class="text-danger"
              >
                {{ get(validation, 'seller_inn[0]') }}
              </small>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <b-form-group
              label="Час разделитель"
              label-for="delivery_separator_hour"
            >
              <v-select
                id="select_delivery_separator_hour"
                v-model.trim="item.delivery_separator_hour"
                placeholder="Выберите час"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="hoursOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="update_offers_price_hour"
                label="label"
                :class="{
                  'is-invalid': !!get(validation, 'delivery_separator_hour[0]')
                }"
              />
              <small
                v-if="!!get(validation, 'delivery_separator_hour[0]')"
                class="text-danger"
              >
                {{ get(validation, 'delivery_separator_hour[0]') }}
              </small>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <b-form-group
              label="Дней до разделителя"
              label-for="delivery_days_before_separator_hour"
              description="Количество дней, через которое заказ будет доставлен, если он оформлен до времени разделителя."
            >
              <b-form-input
                id="delivery_days_before_separator_hour"
                v-model.trim="item.delivery_days_before_separator_hour"
                type="number"
                placeholder="Выберите название доставки"
                :state="get(validation, 'delivery_days_before_separator_hour[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'delivery_days_before_separator_hour[0]')"
                class="text-danger"
              >
                {{ get(validation, 'delivery_days_before_separator_hour[0]') }}
              </small>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <b-form-group
              label="Дней после разделителя"
              label-for="delivery_days_after_separator_hour"
              description="Количество дней, через которое заказ будет доставлен, если он оформлен после времени разделителя."
            >
              <b-form-input
                id="delivery_days_after_separator_hour"
                v-model.trim="item.delivery_days_after_separator_hour"
                type="number"
                placeholder="Выберите название доставки"
                :state="get(validation, 'delivery_days_after_separator_hour[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'delivery_days_after_separator_hour[0]')"
                class="text-danger"
              >
                {{ get(validation, 'delivery_days_after_separator_hour[0]') }}
              </small>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <h4>Merlion</h4>
          </b-col>

          <b-col
            cols="12"
          >
            <b-form-group
              label="Код клиента *"
              label-for="merlion_client"
            >
              <b-form-input
                id="merlion_client"
                v-model.trim="item.merlion_client"
                placeholder="Введите код клиента"
                :state="get(validation, 'merlion_client[0]') ? false : null"
                readonly
              />
              <small
                v-if="!!get(validation, 'merlion_client[0]')"
                class="text-danger"
              >
                {{ get(validation, 'merlion_client[0]') }}
              </small>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <b-form-group
              label="Логин *"
              label-for="merlion_login"
            >
              <b-form-input
                id="merlion_login"
                v-model.trim="item.merlion_login"
                placeholder="Введите логин"
                :state="get(validation, 'merlion_login[0]') ? false : null"
                readonly
              />
              <small
                v-if="!!get(validation, 'merlion_login[0]')"
                class="text-danger"
              >
                {{ get(validation, 'merlion_login[0]') }}
              </small>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
          >
            <b-form-group
              label="Пароль *"
              label-for="merlion_password"
            >
              <b-form-input
                id="merlion_password"
                v-model.trim="item.merlion_password"
                placeholder="Введите пароль"
                :state="get(validation, 'merlion_password[0]') ? false : null"
                readonly
                type="password"
              />
              <small
                v-if="!!get(validation, 'merlion_password[0]')"
                class="text-danger"
              >
                {{ get(validation, 'merlion_password[0]') }}
              </small>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <b-form-group
              label="Час формирования суточного заказа"
              label-for="daily_order_hour"
            >
              <v-select
                id="select_daily_order_hour"
                v-model.trim="item.daily_order_hour"
                placeholder="Выберите час"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="['0','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23']"
                :reduce="val => val"
                :clearable="false"
                input-id="daily_order_hour"
                :class="{
                  'is-invalid': !!get(validation, 'daily_order_hour[0]')
                }"
              />
              <small
                v-if="!!get(validation, 'daily_order_hour[0]')"
                class="text-danger"
              >
                {{ get(validation, 'daily_order_hour[0]') }}
              </small>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <b-form-group
              label="Контрагент"
              label-for="merlion_counter_agent"
            >
              <v-select
                id="select_merlion_counter_agent"
                v-model.trim="item.merlion_counter_agent"
                placeholder="Выберите контрагента"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="merlionAgents"
                :reduce="val => val.Code"
                :clearable="false"
                input-id="merlion_counter_agent"
                label="Description"
                :class="{
                  'is-invalid': !!get(validation, 'merlion_counter_agent[0]')
                }"
              />
              <small
                v-if="!!get(validation, 'merlion_counter_agent[0]')"
                class="text-danger"
              >
                {{ get(validation, 'merlion_counter_agent[0]') }}
              </small>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <h4>Fresh Logic</h4>
          </b-col>

          <b-col
            cols="12"
          >
            <b-form-group
              label="Логин *"
              label-for="fresh_login"
            >
              <b-form-input
                id="fresh_login"
                v-model.trim="item.fresh_login"
                placeholder="Введите логин"
                :state="get(validation, 'fresh_login[0]') ? false : null"
                readonly
              />
              <small
                v-if="!!get(validation, 'fresh_login[0]')"
                class="text-danger"
              >
                {{ get(validation, 'fresh_login[0]') }}
              </small>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <b-form-group
              label="Пароль *"
              label-for="fresh_password"
            >
              <b-form-input
                id="fresh_password"
                v-model.trim="item.fresh_password"
                placeholder="Введите пароль"
                type="password"
                :state="get(validation, 'fresh_password[0]') ? false : null"
                readonly
              />
              <small
                v-if="!!get(validation, 'fresh_password[0]')"
                class="text-danger"
              >
                {{ get(validation, 'fresh_password[0]') }}
              </small>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <b-form-group
              label="Email адрес менеджера *"
              label-for="fresh_manager_email"
              description="На данный адрес будет отправляться письмо со списком заказов и документами на отгрузку на текущий день."
            >
              <b-form-input
                id="fresh_manager_email"
                v-model.trim="item.fresh_manager_email"
                placeholder="Введите пароль"
                :state="get(validation, 'fresh_manager_email[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'fresh_manager_email[0]')"
                class="text-danger"
              >
                {{ get(validation, 'fresh_manager_email[0]') }}
              </small>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <b-form-group
              label="Час формирования письма"
              label-for="daily_email_hour"
            >
              <v-select
                id="select_daily_email_hour"
                v-model.trim="item.daily_email_hour"
                placeholder="Выберите час"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="['0','1','2','3','4','5','6','7','8','9','11','12','13','14','15','16','17','18','19','20','21','22','23']"
                :reduce="val => val"
                :clearable="false"
                input-id="daily_email_hour"
                :class="{
                  'is-invalid': !!get(validation, 'daily_email_hour[0]')
                }"
              />
              <small
                v-if="!!get(validation, 'daily_email_hour[0]')"
                class="text-danger"
              >
                {{ get(validation, 'daily_email_hour[0]') }}
              </small>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col
            cols="12"
          >
            <h4 class="mb-1">
              Правила выгрузки
            </h4>
          </b-col>
          <b-col
            cols="12"
          >
            <remain-prices-rules
              :price-rules.sync="item.remain_by_price_rules"
            />
            <remain-margin-rules
              :margin-rules.sync="item.remain_by_margin_rules"
            />

            <dimensions-rules
              :dimensions-rules.sync="item.dimensions_rules"
            />

          </b-col>

          <b-col
            cols="12"
          >
            <h4>Другое</h4>
          </b-col>

          <b-col
            cols="12"
          >
            <b-form-group
              label="Час обновления цен"
              label-for="update_offers_price_hour"
              description="Час в который будут обновляться цены в торговых предложениях из Merlion"
            >
              <v-select
                id="select_update_offers_price_hour"
                v-model.trim="item.update_offers_price_hour"
                placeholder="Выберите час"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="updateOffersPriceHourOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="update_offers_price_hour"
                label="label"
                :class="{
                  'is-invalid': !!get(validation, 'update_offers_price_hour[0]')
                }"
              />
              <small
                v-if="!!get(validation, 'update_offers_price_hour[0]')"
                class="text-danger"
              >
                {{ get(validation, 'update_offers_price_hour[0]') }}
              </small>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col>
            <b-button
              variant="primary"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="updateItem"
            >
              Сохранить
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card>
      <FAQInfo />
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BAlert, BButton, BCard, BCol, BFormGroup, BFormInput, BLink, BOverlay, BRow, BFormCheckbox,
} from 'bootstrap-vue'
import get from 'lodash/get'
import { getCurrentInstance, ref, onMounted } from 'vue'
import vSelect from 'vue-select'
import {
  updateMerlionYmdbsIntegration as updateMerlionYmdbsIntegrationApi,
  getMerlionYmdbsIntegration as getMerlionYmdbsIntegrationApi,
  getMerlionCounterAgents,
} from '@/services/main-api/integrations/merlion-ymdbs'
import useCrudEdit from '@/composables/useCrudEdit'
import { types } from '@/composables/useIntegrations'
import RemainPricesRules from '@/modules/integrations/components/RemainPricesRules.vue'
import RemainMarginRules from '@/modules/integrations/components/RemainMarginRules.vue'
import DimensionsRules from '@/modules/integrations/components/DimensionsRules.vue'
import FAQInfo from '../FAQInfo.vue'

export default {
  name: 'EditForm',
  components: {
    RemainPricesRules,
    RemainMarginRules,
    DimensionsRules,
    BAlert,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BOverlay,
    BFormCheckbox,
    FAQInfo,
    vSelect,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
  },
  setup(props) {
    const instance = getCurrentInstance().proxy
    const getItemError = ref(false)

    const merlionAgents = ref([])

    const updateOffersPriceHourOptions = [
      { label: 'Не обновлять', value: null },
    ]

    for (let i = 0; i < 24; i += 1) {
      updateOffersPriceHourOptions.push({
        label: `${i}`,
        value: i,
      })
    }

    const hoursOptions = []

    for (let i = 0; i < 24; i += 1) {
      hoursOptions.push({
        label: `${i}`,
        value: i,
      })
    }
    const getMerlionAgents = async () => {
      try {
        const res = await getMerlionCounterAgents({
          client: instance.item.merlion_client,
          login: instance.item.merlion_login,
          password: instance.item.merlion_password,
        })
        merlionAgents.value = res.data.data
      } catch (e) {
        merlionAgents.value = []
      }
    }

    const getItem = async () => {
      try {
        const res = await getMerlionYmdbsIntegrationApi({ id: props.id })
        instance.item = {
          ...res.data.data,
          type: 'merlion_ymdbs',
        }

        await getMerlionAgents()
      } catch (e) {
        instance.item = {}
        instance.getItemError = true
      }
    }

    const updateEntity = async () => {
      await updateMerlionYmdbsIntegrationApi({
        id: instance.item.id,
        name: instance.item.name,
        merlion_counter_agent: instance.item.merlion_counter_agent,
        client_secret: instance.item.client_secret,
        campaign_token: instance.item.campaign_token,
        warehouse_id: instance.item.warehouse_id,
        include_vat: instance.item.include_vat,
        fresh_manager_email: instance.item.fresh_manager_email,
        daily_order_hour: instance.item.daily_order_hour,
        daily_email_hour: instance.item.daily_email_hour,
        remain_by_price_rules: instance.item.remain_by_price_rules,
        remain_by_margin_rules: instance.item.remain_by_margin_rules,
        dimensions_rules: instance.item.dimensions_rules,
        update_offers_price_hour: instance.item.update_offers_price_hour,
      })
    }

    const {
      item,
      loading,
      validation,
      error_message,
      clearForm,
      updateItem,
    } = useCrudEdit({
      item: {
        id: null,
        name: '',
        merlion_counter_agent: '',
        client_secret: '',
        campaign_token: '',
        warehouse_id: '',
        include_vat: false,
        daily_order_hour: '1',
        remain_by_price_rules: [],
        remain_by_margin_rules: [],
        dimensions_rules: [],
        update_offers_price_hour: null,
        old_shop: false,
        delivery_type: 'DELIVERY',
        delivery_name: '',
        delivery_separator_hour: 0,
        delivery_days_before_separator_hour: 0,
        delivery_days_after_separator_hour: 0,
        seller_inn: '',
      },
      validation: {
        rules: {
          name: 'required',
          client_secret: 'required',
          campaign_token: 'required',
          warehouse_id: 'required',
          daily_order_hour: 'required',
          delivery_type: 'required',
          delivery_name: 'required',
          delivery_separator_hour: 'required',
          delivery_days_before_separator_hour: 'required',
          delivery_days_after_separator_hour: 'required',
          seller_inn: 'required',
        },
        customErrorMessages: {
          'required.name': 'Укажите название',
          'required.client_secret': 'Укажите Client secret',
          'required.campaign_token': 'Укажите авторизационный токен кампании',
          'required.warehouse_id,': 'Укажите идентификатор склада кампании',
          'required.daily_order_hour': 'Укажите час',
          'required.delivery_type': 'Укажите тип доставки',
          'required.delivery_name': 'Укажите название доставки',
          'required.delivery_separator_hour': 'Укажите час разделителя',
          'required.delivery_days_before_separator_hour': 'Укажите количество дней',
          'required.delivery_days_after_separator_hour': 'Укажите количество дней',
          'required.seller_inn': 'Укажите ИНН продавца',
        },
      },
      getItem,
      updateEntity,
      getTextAfterUpdate: () => 'Интеграция была изменена.',
      runAfterUpdate: async () => {
        await instance.$router.go(-1)
      },
    })

    onMounted(() => {
      if (!instance.$store.getters['workingMode/mode']) {
        instance.$router.push({ name: 'dashboard' })
      }
    })

    return {
      item,
      loading,
      validation,
      error_message,
      clearForm,
      updateItem,

      types,

      merlionAgents,

      updateOffersPriceHourOptions,

      get,
      getItemError,

      hoursOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
