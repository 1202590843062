<template>
  <b-card
    title="По стоимости:"
    class="border mb-1"
  >
    <div class="">

      <b-form-row>
        <b-col
          cols="12"
          md=""
        >
          <b-form-group
            label="Тип"
            label-for="prices_rules_type"
          >
            <v-select
              id="select_prices_rules_type"
              v-model.trim="selectedRulesType"
              placeholder="Выберите тип"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="rulesTypes"
              :reduce="val => val.value"
              :clearable="false"
              label="name"
              input-id="prices_rules_type"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="['from', 'range'].includes(selectedRulesType)"
          cols="12"
          md=""
        >
          <b-form-group
            label="Цена от"
            label-for="price-from"
          >
            <b-input-group
              append="₽"
            >
              <b-form-input
                id="price-from"
                v-model.trim="selectedRulesFrom"
                placeholder="Цена"
                :state="get(validation, 'from[0]') ? false : null"
                type="number"
                lang="ru"
              />
            </b-input-group>

            <small
              v-if="!!get(validation, 'from[0]')"
              class="text-danger"
            >
              {{ get(validation, 'from[0]') }}
            </small>
          </b-form-group>
        </b-col>
        <b-col
          v-if="['to', 'range'].includes(selectedRulesType)"
          cols="12"
          md=""
        >
          <b-form-group
            label="Цена до"
            label-for="price-to"
          >
            <b-input-group
              append="₽"
            >
              <b-form-input
                id="price-from"
                v-model.trim="selectedRulesTo"
                placeholder="Цена"
                :state="get(validation, 'to[0]') ? false : null"
                type="number"
                lang="ru"
              />
            </b-input-group>

            <small
              v-if="!!get(validation, 'to[0]')"
              class="text-danger"
            >
              {{ get(validation, 'to[0]') }}
            </small>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md=""
        >
          <b-form-group
            label="Выгружать от"
            label-for="price-remain-count"
          >
            <b-input-group
              append="шт."
            >
              <b-form-input
                id="price-remain-count"
                v-model.trim="selectedRulesRemainCount"
                placeholder="Остаток"
                :state="get(validation, 'remain_count[0]') ? false : null"
                type="number"
                lang="ru"
              />
            </b-input-group>

            <small
              v-if="!!get(validation, 'remain_count[0]')"
              class="text-danger"
            >
              {{ get(validation, 'remain_count[0]') }}
            </small>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-button
        variant="outline-primary"
        size="sm"
        @click="addPricesRule"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-50"
        />
        <span class="align-middle">Добавить</span>
      </b-button>

      <b-table-simple
        :striped="true"
        :bordered="false"
        :borderless="true"
        :outlined="false"
        :small="false"
        :hover="false"
        :dark="false"
        :fixed="false"
        :responsive="true"
        class="mt-2 mb-0"
      >
        <b-thead>
          <b-tr>
            <b-th colspan="2">
              Добавленные правила
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody
          v-if="priceRules.length"
        >
          <b-tr
            v-for="(priceRule, priceRuleIndex) in priceRules"
            :key="priceRuleIndex"
          >
            <b-td>
              <div
                v-if="priceRule.type === 'from'"
              >
                Если цена от <strong>{{ priceRule.from }}</strong> выгружать от <strong>{{ priceRule.remain_count }}</strong> шт.
              </div>
              <div
                v-if="priceRule.type === 'to'"
              >
                Если цена до <strong>{{ priceRule.to }}</strong> выгружать от <strong>{{ priceRule.remain_count }}</strong> шт.
              </div>
              <div
                v-if="priceRule.type === 'range'"
              >
                Если цена от <strong>{{ priceRule.from }}</strong> и до <strong>{{ priceRule.to }}</strong>
                выгружать от <strong>{{ priceRule.remain_count }}</strong> шт.
              </div>
            </b-td>
            <b-td class="text-right">
              <b-button
                variant="outline-danger"
                class="btn-icon"
                size="sm"
                @click="deleteRule(priceRuleIndex)"
              >
                <feather-icon icon="XIcon" />
              </b-button>
            </b-td>
          </b-tr>
        </b-tbody>
        <b-tbody
          v-else
        >
          <b-tr>
            <b-td colspan="2">
              <div class="text-secondary">
                -
              </div>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
  </b-card>
</template>

<script>
import get from 'lodash/get'
import vSelect from 'vue-select'
import {
  ref, watch,
} from 'vue'
import {
  BCard, BCol, BFormGroup, BFormInput, BButton,
  BInputGroup, BFormRow,
  BTableSimple, BTbody, BTr, BTh, BTd, BThead,
} from 'bootstrap-vue'

export default {
  name: 'RemainPricesRules',
  components: {
    vSelect,
    BCard,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroup,
    BFormRow,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BThead,
  },
  props: {
    priceRules: {
      type: Array,
      default: () => ([]),
    },
  },
  setup(props, { emit }) {
    const rulesTypes = [
      { name: 'От', value: 'from' },
      { name: 'До', value: 'to' },
      { name: 'Диапазон', value: 'range' },
    ]
    const validation = ref({})

    // BEGIN PricesRules

    // END PricesRules
    const selectedRulesType = ref('from')
    const selectedRulesFrom = ref(0)
    const selectedRulesTo = ref(0)
    const selectedRulesRemainCount = ref(0)

    const clearPricesRulesForm = () => {
      selectedRulesType.value = 'from'
      selectedRulesFrom.value = 0
      selectedRulesTo.value = 0
      selectedRulesRemainCount.value = 0
    }

    const clearValidation = () => {
      validation.value = {}
    }

    const checkValidation = () => {
      clearValidation()

      let hasError = false

      if (selectedRulesType.value === 'to' && selectedRulesTo.value <= 0) {
        hasError = true
        validation.value.to = ['Цена должна быть больше 0']
      }

      if (selectedRulesType.value === 'range' && selectedRulesFrom.value >= selectedRulesTo.value) {
        hasError = true
        validation.value.from = ['"Цена от" должна быть меньше "Цены до"']
        validation.value.to = ['"Цена до" должна быть больше "Цены от"']
      }

      return !hasError
    }

    const deleteRule = index => {
      const rules = [...props.priceRules]
      rules.splice(index, 1)
      emit('update:priceRules', rules)
    }

    const addPricesRule = () => {
      if (!checkValidation()) {
        return
      }

      const rule = {
        type: selectedRulesType.value,
        remain_count: selectedRulesRemainCount.value,
      }
      if (['from', 'range'].includes(selectedRulesType.value)) {
        rule.from = selectedRulesFrom.value
      }

      if (['to', 'range'].includes(selectedRulesType.value)) {
        rule.to = selectedRulesTo.value
      }

      emit('update:priceRules', [...props.priceRules, rule])

      clearPricesRulesForm()
    }

    watch(selectedRulesType, () => {
      clearValidation()
    })

    return {
      rulesTypes,

      selectedRulesType,
      selectedRulesFrom,
      selectedRulesTo,
      selectedRulesRemainCount,
      addPricesRule,
      deleteRule,
      validation,

      get,
    }
  },
}
</script>

<style scoped lang="scss">

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
